import {Button, Modal, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {paginationHelper} from "../../../Helpers/Table";
import {displayDateTime} from "../../../Helpers/DateTime";
import {AdminChangePassword} from "../../UserManagement/Admin/AdminChangePassword";
import {AdminDelete} from "../../UserManagement/Admin/AdminDelete";
import {CatalogueRequestApprove} from "./RequestApprove";
import {CatalogueRequestReject} from "./RequestReject";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";
import {CustomTableSuccessButton} from "../../../Components/CustomTableSuccessButton/CustomTableSuccessButton";
import {CustomTableFailedButton} from "../../../Components/CustomTableFailedButton/CustomTableFailedButton";
import {CustomTable} from "../../../Components/CustomTable/CustomTable";

export const CataloguePage = () => {

    const [requestId, setRequestId] = useState(null);
    const [approveRequestModalVisible, setApproveRequestModalVisible] = useState(false);
    const [rejectRequestModalVisible, setRejectRequestModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Catalogue',
            dataIndex: 'catalogueName',
            key: 'catalogueName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Registration Time',
            dataIndex: 'created',
            key: 'created',
            render: (text, record) => {
                return (
                    displayDateTime(record.created)
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <CustomTableSuccessButton
                            onClick={() => {
                                setRequestId(record.id);
                                setApproveRequestModalVisible(true);
                            }}
                            style={{ width: '100%' }}
                        >Approve</CustomTableSuccessButton>
                        <CustomTableFailedButton
                            onClick={() => {
                                setRequestId(record.id);
                                setRejectRequestModalVisible(true);
                            }}
                        >Reject</CustomTableFailedButton>
                    </Space>
                )
            }
        },
    ];

    const onGetCatalogueList = () => {
        setLoading(true)
        backendAxiosInstance.get('/api/catalogue/request')
            .then((res) => {
                setDataSource(res.data);
                setTotalElements(res.totalElements);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        onGetCatalogueList();
    }, [])

    return (
        <>
            <Space direction={"vertical"} style={{width: '100%'}}>
                <CustomPageTitle>Request</CustomPageTitle>
                <CustomTable dataSource={dataSource} columns={columns} loading={loading} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} totalElements={totalElements}/>
            </Space>

            <Modal title="Approve Request" open={approveRequestModalVisible}
                   onOk={() => setApproveRequestModalVisible(false)}
                   onCancel={() => setApproveRequestModalVisible(false)} footer={null}>
                <CatalogueRequestApprove requestId={requestId} onCloseModal={() => {
                    setApproveRequestModalVisible(false)
                    onGetCatalogueList()
                }}/>
            </Modal>

            <Modal title="Reject Request" open={rejectRequestModalVisible}
                   onOk={() => setRejectRequestModalVisible(false)}
                   onCancel={() => setRejectRequestModalVisible(false)} footer={null}>
                <CatalogueRequestReject requestId={requestId} onCloseModal={() => {
                    setRejectRequestModalVisible(false)
                    onGetCatalogueList()
                }}/>
            </Modal>
        </>
    )
}
