import {Avatar, Button, Col, Layout, Menu, Row, Space} from 'antd';
import React, {useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";

import "./ClientLayout.less";

import logo from "../../Assets/transparent-bg-white-logo.png";

const {Header} = Layout;

export const ClientLayout = () => {

	return (
		<>
			<Outlet/>
		</>
	)
}
