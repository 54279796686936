import {Input} from "antd";

export const CustomInputPassword = ({  children, prefix, ...props }) => {
    return (
        <div className="input-group prefix">
            <span className="input-group-addon">{ prefix }</span>
            <Input.Password {...props} style={{ height: "5vh", fontSize: '1.2vw', backgroundColor: '#ffe5e9' }}>{ children }</Input.Password>
        </div>
    )
}
