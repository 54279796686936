import moment from "moment/moment";

export const displayDateTime = (unixTime, format) => {
    if (!format) {
        return moment.unix(unixTime/1000).format("MM/DD/YYYY")
    }


    return moment.unix(unixTime/1000).format(format)
}
