import {useEffect, useState} from "react";
import {Button, Form, Input, Space} from "antd";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {showSuccessNotification} from "../../../Components/Notification/Notification";
import {UserOutlined} from "@ant-design/icons";
import {CustomInputPassword} from "../../../Components/CustomInputPassword/CustomInputPassword";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";

import lockIcon from "../../../Assets/icons/lock.png";
import {CustomPrefix} from "../../../Components/CustomPrefix/CustomPrefix";

export const ChangePasswordPage = () => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		setLoading(true);
		backendAxiosInstance.post('/api/user/changePassword', values)
			.then(() => {
				showSuccessNotification("Updated password successfully")
			})
			.finally(() => {
				setLoading(false)
			})
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Space direction={"vertical"} style={{width: '70%'}}>
			<CustomPageTitle>Change Password</CustomPageTitle>
			<Form
				form={form}
				labelCol={{span: 2}}
				wrapperCol={{span: 22}}
				initialValues={{remember: true}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					name="oldPassword"
					rules={[{ required: true, message: 'Please input your old password!', whitespace: true }]}
				>
					<CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"Old Password"} />
				</Form.Item>

				<Form.Item
					name="newPassword"
					rules={[{ required: true, message: 'Please input your new password!', whitespace: true }]}
				>
					<CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"New Password"} />
				</Form.Item>

				<Form.Item
					name="confirmedPassword"
					rules={[{ required: true, message: 'Please input your confirmed password!', whitespace: true }]}
				>
					<CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"Confirmed Password"} />
				</Form.Item>

				<CustomButton type="primary" htmlType="submit" loading={loading}>
					Update
				</CustomButton>
			</Form>
		</Space>
	)
}
