import {useState} from "react";
import {Col, DatePicker, Form, Row} from "antd";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {CustomInput} from "../../../Components/CustomInput/CustomInput";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {showSuccessNotification} from "../../../Components/Notification/Notification";

export const CreateTourAccess = ({onCloseModal = () => {}}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true)
        backendAxiosInstance.post(`/api/access`, {
            name: values['name'],
            type: 'tour',
            startTime: values['startTime']?.unix() * 1000,
            endTime: values['endTime']?.unix() * 1000
        })
            .then((res) => {
                showSuccessNotification("Access created successfully")
                onCloseModal()
            })
            .finally(() => {
                setLoading(false)
            });
    };

    return (
        <Form
            form={form}
            labelCol={{span: 2}}
            initialValues={{remember: true}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input the access name!', whitespace: true }]}
            >
                <CustomInput placeholder={"Name"} />
            </Form.Item>

            <Form.Item
                name="startTime"
                rules={[{required: true, message: 'Please input the start time!'}]}
            >
                <DatePicker style={{backgroundColor: '#ffe5e9', height: "5vh", fontSize: '1.2vw'}}
                            showTime>
                </DatePicker>
            </Form.Item>

            <Form.Item
                name="endTime"
                rules={[{required: true, message: 'Please input end time!'}, ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('startTime')?.unix() < value.unix()) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error("end time must before start time!!"));

                    }
                })]}
            >
                <DatePicker style={{backgroundColor: '#ffe5e9', height: "5vh", fontSize: '1.2vw'}}
                            showTime>
                </DatePicker>
            </Form.Item>

            <CustomButton type="primary" htmlType="submit" loading={loading}>
                Submit
            </CustomButton>
        </Form>
    )
}