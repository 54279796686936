import {Form, Input, Button} from "antd";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {useState} from "react";
import {showSuccessNotification} from "../../../Components/Notification/Notification";
import {CustomInputPassword} from "../../../Components/CustomInputPassword/CustomInputPassword";
import {LockOutlined} from "@ant-design/icons";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";

import lockIcon from "../../../Assets/icons/lock.png";
import {CustomPrefix} from "../../../Components/CustomPrefix/CustomPrefix";

export const AdminChangePassword = ({ adminId, onCloseModal }) => {

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true)
        backendAxiosInstance.post(`/user/${adminId}/changePassword`, {
            password: values.password,
            confirmedPassword: values.confirmedPassword
        })
            .then((res) => {
                showSuccessNotification("Change password successfully")
                onCloseModal()
            })
            .finally(() => {
                setLoading(false)
            });
    };

    return (
        <Form
            onFinish={onFinish}
        >

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <CustomInputPassword prefix={<CustomPrefix src={lockIcon}/>} placeholder={"Password"} />
            </Form.Item>

            <Form.Item
                name="confirmedPassword"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <CustomInputPassword prefix={<CustomPrefix src={lockIcon}/>} placeholder={"Confirmed Password"} />
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
                <CustomButton type="primary" htmlType="submit" loading={loading}>
                    Submit
                </CustomButton>
            </div>
        </Form>
    )
}
