import {Button, Modal, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {displayDateTime} from "../../../Helpers/DateTime";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {paginationHelper} from "../../../Helpers/Table";
import {AdminChangePassword} from "./AdminChangePassword";
import {AdminDelete} from "./AdminDelete";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";
import {CustomTableSuccessButton} from "../../../Components/CustomTableSuccessButton/CustomTableSuccessButton";
import {CustomTableFailedButton} from "../../../Components/CustomTableFailedButton/CustomTableFailedButton";

export const AdminPage = () => {

	const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [adminId, setAdminId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalElements, setTotalElements] = useState(0);
	const [loading, setLoading] = useState(false)
	const [dataSource, setDataSource] = useState([]);

	const columns = [
		{
			title: 'First Name',
			dataIndex: 'firstName',
			key: 'firstName',
		},
		{
			title: 'Last Name',
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Company',
			dataIndex: 'company',
			key: 'company',
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Registration Time',
			dataIndex: 'created',
			key: 'created',
			render: (text, record) => {
				return displayDateTime(record.created)
			}
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) => {
				return (
					<Space size="middle">
						<CustomTableSuccessButton style={{ width: '100%' }} onClick={() => {
							setAdminId(record.id)
							setChangePasswordModalVisible(true)
						}}>Change Password</CustomTableSuccessButton>
						<CustomTableFailedButton onClick={() => {
							setAdminId(record.id)
							setDeleteModalVisible(true)
						}}>Delete</CustomTableFailedButton>
					</Space>
				)
			}
		},
	];

	const onGetAdminList = () => {
		backendAxiosInstance.get('/api/users/active?sortOrder=DESC')
			.then((res) => {
				setDataSource(res.data);
				setTotalElements(res.totalPages);
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		setLoading(true)
		onGetAdminList()
	}, [])

	return (
		<>
			<Space direction={"vertical"} style={{ width: '100%' }}>
				<CustomPageTitle>Admin</CustomPageTitle>
				<div style={{ padding: 30, backgroundColor: '#F6E7EA', borderRadius: 30 }}>
					<Table dataSource={dataSource} columns={columns} loading={loading} pagination={paginationHelper(totalElements, setCurrentPage, setPageSize, currentPage, pageSize)} />
				</div>
			</Space>

			<Modal title="Change Password" open={changePasswordModalVisible} onOk={() => setChangePasswordModalVisible(false)} onCancel={() => setChangePasswordModalVisible(false)} footer={null}>
				<AdminChangePassword adminId={adminId} onCloseModal={() => {
					setChangePasswordModalVisible(false)
					onGetAdminList()
				}} />
			</Modal>

			<Modal title="Delete Admin" open={deleteModalVisible} onOk={() => setDeleteModalVisible(false)} onCancel={() => setDeleteModalVisible(false)} footer={null}>
				<AdminDelete adminId={adminId} onCloseModal={() =>{
					setDeleteModalVisible(false)
					onGetAdminList()
				}} />
			</Modal>
		</>
	)
}
