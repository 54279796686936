import {Avatar, Breadcrumb, Button, Col, Layout, Menu, Row, Space} from 'antd';
import {
    AppstoreOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";

import logo from "../../Assets/transparent-bg-white-logo.png";

import "./AdminLayout.less";
import useWindowsDimensionHook from "../../Hooks/useWindowsDimensionHook";

const {Header, Sider, Content} = Layout;


export const AdminLayout = () => {

    const { isMobile } = useWindowsDimensionHook();

    const [items, setItems] = useState([
        getItem('Resource', 'sub2', <AppstoreOutlined/>, [
            getItem('Catalogue', 'sub3', <AppstoreOutlined/>, [
                getItem('Upload', '/resource/catalogue/upload'),
                getItem('Request', '/resource/catalogue/request'),
                getItem('History', '/resource/catalogue/history'),
            ]),
            getItem('Tour', '/resource/tour'),
        ]),
        getItem('Personal', 'sub4', <SettingOutlined/>, [
            getItem('Info', '/personal/info'),
            getItem('Change Password', '/personal/change-password'),
        ]),
        getItem('Log Out', '/logout', <UserOutlined/>)
    ])
    const [currentRole, setCurrentRole] = useState(null);
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate()
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const onClick = (e) => {

        if (e.key === '/logout') {
            localStorage.clear();
            navigate('/login');
        } else {
            navigate(e.key)
            setCurrent(e.key);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <div onClick={() => setIsResetPasswordVisible(true)}>
                    Reset Password
                </div>
            </Menu.Item>
        </Menu>
    )

    useEffect(() => {
        if (localStorage.getItem('role')) {
            setCurrentRole(localStorage.getItem('role'))
        }
    }, [localStorage])

    useEffect(() => {
        if (currentRole === 'SUPER_ADMIN') {
            setItems((item) => [getItem('User Management', 'sub1', <UserOutlined/>, [
                getItem('Admin', '/user-management/admin'),
                getItem('Registration', '/user-management/registration'),
            ]), ...item])
        }
    }, [currentRole])

    return (
        <>
            {
                isMobile ? (
                    <h1>Please open using desktop</h1>
                ) : (
                    <>
                        <Layout style={{ minHeight: "100vh" }}>
                            <Sider trigger={null} collapsible collapsed={isCollapsed} width={220}>
                                <div style={{padding: "10%"}}>
                                    <img className="logo" src={logo} style={{width: 150}}/>
                                </div>
                                <Menu
                                    theme="dark"
                                    mode="inline"
                                    defaultSelectedKeys={['1']}
                                    items={items}
                                    onClick={onClick}
                                />
                            </Sider>
                            <Layout>
                                <Content className={"admin-main-container"}>
                                    <Row style={{
                                        marginTop: '30px',
                                    }}>
                                        <Col span={4} offset={20}>
                                            <Space direction={"horizontal"} size={1}>
                                                <img src={require("../../Assets/icons/person_circle.png")} style={{ width: '70%' }} />
                                                <h1 style={{ color: 'white' }}>{localStorage.getItem("firstName")} {localStorage.getItem("lastName")}</h1>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <div style={{
                                        margin: '2px 16px',
                                        padding: 24,
                                        minHeight: 280,
                                    }}>
                                        <Outlet />
                                    </div>
                                </Content>
                            </Layout>
                        </Layout>
                    </>
                )
            }
        </>
    )
}
