import useWindowsDimensionHook from "../../Hooks/useWindowsDimensionHook";
import {MobileClientRequestPage} from "./Mobile/ClientRequestPage";
import {WebClientRequestPage} from "./Web/ClientRequestPage";

export const ClientRequestPage = () => {

    const { isMobile } = useWindowsDimensionHook();

    return (
        isMobile ? <MobileClientRequestPage /> : <WebClientRequestPage />
    )
}
