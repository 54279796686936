import {useEffect, useState} from "react";
import {displayDateTime} from "../../../Helpers/DateTime";
import {Button, Grid, Modal, Space} from "antd";
import {CustomTableSuccessButton} from "../../../Components/CustomTableSuccessButton/CustomTableSuccessButton";
import {CustomTableFailedButton} from "../../../Components/CustomTableFailedButton/CustomTableFailedButton";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";
import {CustomTable} from "../../../Components/CustomTable/CustomTable";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {CreateTourAccess} from "./CreateTourAccess";
import moment from "moment/moment";
import {showSuccessNotification} from "../../../Components/Notification/Notification";

export const TourPage = () => {
    const [createTourAccessModalVisible, setCreateTourAccessModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([]);



    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, record) => {
                console.log(record);
                return (
                    displayDateTime(record.startTime, "DD/MM/yyyy HH:mm:ss")
                )
            }
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (text, record) => {
                return (
                    displayDateTime(record.endTime, "DD/MM/yyyy HH:mm:ss")
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    ['pending', 'active'].includes(record.status) &&
                    <>
                        <Space size="middle">
                            <CustomTableFailedButton
                                onClick={() => {
                                    disableAccess(record.id)
                                }}
                            >Reject</CustomTableFailedButton>
                        </Space>
                        <Space size="middle" style={{paddingLeft: 10}}>
                            <CustomTableSuccessButton
                                onClick={() => {
                                    copyTourLink(record.token);
                                }}
                            >Link</CustomTableSuccessButton>
                        </Space>
                    </>
                )
            }
        },
    ];

    const getStatus = (access) => {
        if (access.disabled) {
            return 'disabled';
        }

        const now = moment().unix() * 1000;

        if (now > access.startTime && now < access.endTime) {
            return 'active';
        } else if (now < access.startTime) {
            return 'pending';
        } else {
            return 'expired';
        }
    }

    const copyTourLink = (token) => {
        setLoading(true);
        backendAxiosInstance.get('/api/tour/link').then((data) => {
            const link = `${data.link}?token=${token}`;
            showSuccessNotification("tour link has copied to your clipboard");
            navigator.clipboard.writeText(link);
            }

        ).finally(() => {
            setLoading(false);
            }
        )
    }

    const disableAccess = (id) => {
        setLoading(true);
        backendAxiosInstance.post('/api/access/disable', {
            id
        })
            .finally(() => {
                setLoading(false);
                onGetCatalogueList();
            })

    }

    const onGetCatalogueList = () => {
        setLoading(true)
        backendAxiosInstance.get('/api/access?type=tour')
            .then((res) => {
                setDataSource(res.data.map(x => ({
                        ...x,
                        status: getStatus(x)
                    }
                )));
                setTotalElements(res.totalElements);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        onGetCatalogueList();
    }, [])

    return (
        <>
            <Space direction={"vertical"} style={{width: '100%'}}>
                <CustomPageTitle>Tour</CustomPageTitle>
                <CustomButton type="primary" onClick={() => {setCreateTourAccessModalVisible(true)}} >
                    New Access
                </CustomButton>
                <CustomTable dataSource={dataSource} columns={columns} loading={loading} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} totalElements={totalElements}/>
            </Space>

            <Modal title="Create New Access" open={createTourAccessModalVisible}
                   onOk={() => setCreateTourAccessModalVisible(false)}
                   onCancel={() => setCreateTourAccessModalVisible(false)} footer={null}
            >
                <CreateTourAccess
                    onCloseModal={() => {
                        setLoading(true)
                        setCreateTourAccessModalVisible(false)
                        onGetCatalogueList()
                    }}
                />
            </Modal>
        </>
    )
}
