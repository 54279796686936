import {useState} from "react";
import {backendAxiosInstance} from "../../Axios/AxiosBase";
import {Button, Form, Image, Input, Space} from "antd";
import {useNavigate} from "react-router-dom";
import "./SignUpPage.less"
import {showSuccessNotification} from "../../Components/Notification/Notification";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {CustomInput} from "../../Components/CustomInput/CustomInput";
import {CustomInputPassword} from "../../Components/CustomInputPassword/CustomInputPassword";
import {CustomButton} from "../../Components/CustomButton/CustomButton";
import {CustomPrefix} from "../../Components/CustomPrefix/CustomPrefix";

import envelopeIcon from "../../Assets/icons/envelope.png";
import personIcon from "../../Assets/icons/person.png";
import lockIcon from "../../Assets/icons/lock.png";

export const SignUpPage = () => {

    const [maxAttempt, setMaxAttempt] = useState(3);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onFinish = (values) => {
        setLoading(true)
        backendAxiosInstance.post('/api/register', {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmedPassword: values.confirmedPassword
        })
            .then((res) => {
                showSuccessNotification("Sign up successfully")
                navigate('/login')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={"sign-up-container"}>
            <div style={{ textAlign: 'center' }}>
                <Image preview={false} src={require("../../Assets/transparent-bg-logo.png")} />
            </div>
            <br />
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 22 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!', whitespace: true }]}
                >
                    <CustomInput prefix={<CustomPrefix src={envelopeIcon} />} placeholder={"Email"} />
                </Form.Item>

                <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your first name!', whitespace: true }]}
                >
                    <CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"First Name"} />
                </Form.Item>

                <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your last name!', whitespace: true }]}
                >
                    <CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"Last Name"} />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"Password"} />
                </Form.Item>

                <Form.Item
                    name="confirmedPassword"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"Confirmed Password"} />
                </Form.Item>

                <div style={{ textAlign: 'center' }}>
                    <Space direction={"vertical"}>
                        <CustomButton type="primary" htmlType="submit" loading={loading}>
                            Sign Up
                        </CustomButton>
                        <CustomButton type="link" htmlType="button" loading={loading} onClick={() => navigate("/login")}>
                            Back to Login
                        </CustomButton>
                    </Space>
                </div>
            </Form>
        </div>
    )
}
