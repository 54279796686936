import {Form, Input, Button} from "antd";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {useState} from "react";
import {showSuccessNotification} from "../../../Components/Notification/Notification";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";

export const AdminDelete = ({ adminId, onCloseModal }) => {

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true)
        backendAxiosInstance.delete(`/user/${adminId}`)
            .then((res) => {
                showSuccessNotification("Deleted successfully")
                onCloseModal()
            })
            .finally(() => {
                setLoading(false)
            });
    };

    return (
        <Form
            onFinish={onFinish}
        >
            <div style={{ textAlign: 'center' }}>
                <h2>Are you sure want to delete this user?</h2>
                <CustomButton type="primary" htmlType="submit" loading={loading}>
                    Submit
                </CustomButton>
            </div>
        </Form>
    )
}
