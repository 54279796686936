export const paginationHelper = (totalElement, setCurrentPage, setPerPage, currentPage, perPage) => {
    return {
        onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPerPage(pageSize);
        },
        total: totalElement,
        current: currentPage,
        pageSize: perPage,
        pageSizeOptions: ["5", "10", "20", "50", "100"],
        showSizeChanger: true,
    };
};
