import {Button} from "antd";

import "./CustomTableSuccessButton.less";
import {tableButtonGreenBg, tableButtonGreenText} from "../../Assets/Styles/variables";

export const CustomTableSuccessButton = ({  children, style, ...props }) => {
    return (
        <Button {...props} className={"success-button"} style={{  paddingTop: 0, height: '5vh', width: '5.5vw', fontSize: '1vw', borderRadius: '15px', backgroundColor: tableButtonGreenBg, color: tableButtonGreenText, ...style }}>{ children }</Button>
    )
}
