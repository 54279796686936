import axios from "axios";
import {showErrorNotification} from "../Components/Notification/Notification";
import {refreshAccessToken} from "../Helpers/Auth";
import {IS_DEVELOPMENT_MODE} from "../Helpers/Environment";

export const backendAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
})

backendAxiosInstance.interceptors.request.use(function (config) {
    config.time = {startTime: new Date()};
    const token = localStorage.getItem('accessToken');
    if (token)
        config.headers['Authorization'] = `Bearer ${token}`;
    return config;

});

backendAxiosInstance.interceptors.response.use(function (res) {
    res.config.time.endTime = new Date();
    let duration = res.config.time.endTime - res.config.time.startTime;
    IS_DEVELOPMENT_MODE && console.log('duration', `${duration}ms`, 'response', res.data);
    return res.data;

}, async function (error) {
    IS_DEVELOPMENT_MODE && console.log('error', (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString());

    if (error.response.status === 401) {
        // refresh the token
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
            return backendAxiosInstance(originalRequest);
        }
    } else {
        showErrorNotification(error);
    }
    return Promise.reject(error);

});
