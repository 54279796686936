import { useState, useEffect } from 'react';

export default function useWindowsDimensionHook() {

    const { innerWidth, innerHeight } = window;

    const [screenSize, getDimension] = useState({
        width: innerWidth,
        height: innerHeight,
        isMobile: innerWidth <= 768
    });

    const setDimension = () => {
        getDimension({
            width: innerWidth,
            height: innerHeight,
            isMobile: innerWidth <= 768
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    return screenSize
}
