import {useEffect, useState} from "react";
import {Modal, Space} from "antd";
import {CustomTableSuccessButton} from "../../../Components/CustomTableSuccessButton/CustomTableSuccessButton";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";
import {CustomTable} from "../../../Components/CustomTable/CustomTable";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {UploadCatalogue} from "./UploadCatalogue";
import {CustomTableFailedButton} from "../../../Components/CustomTableFailedButton/CustomTableFailedButton";
import {showSuccessNotification} from "../../../Components/Notification/Notification";

export const CatalogueUpload = () => {

    const [requestId, setRequestId] = useState(null);
    const [uploadCatalogueModelVisible, setUploadCatalogueModelVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            render: (text, record) => {
                return record.disabled ? "inactive": "active";

            },
            key: 'status'

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <CustomTableSuccessButton
                            onClick={() => {
                                window.open(record.url, "_blank", "noopener noreferrer");
                            }}
                            style={{ width: '100%' }}
                        >
                            Download
                        </CustomTableSuccessButton>
                        {
                            !record.disabled &&
                            <CustomTableSuccessButton
                                onClick={() => {
                                    onCopyFormLink(record.id);
                                }}
                                style={{ width: '100%' }}
                            >
                                Copy Form Address
                            </CustomTableSuccessButton>
                        }
                        {!record.disabled &&<CustomTableFailedButton
                            onClick={() => {
                                onDeleteCatalogueResource(record.id)
                            }}
                        >
                            Delete
                        </CustomTableFailedButton>}
                    </Space>
                )
            }
        },
    ];

    const onCopyFormLink = (id) => {
        const url = `${window.location.origin}/api/client/catalogue/${id}`;
        showSuccessNotification("form link has copied to your clipboard");
        navigator.clipboard.writeText(url);
    }

    const onDeleteCatalogueResource = (id) => {
        setLoading(true)
        backendAxiosInstance.delete(`/api/resource/catalogue/${id}`)
            .then((res) => {
                showSuccessNotification("Success")
            })
            .finally(() => {
                onGetCatalogueList()
            })

    }

    const onGetCatalogueList = () => {
        setLoading(true)
        backendAxiosInstance.get('/api/resource/catalogue?')
            .then((res) => {
                setDataSource(res.data);
                setTotalElements(res.totalElements);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        onGetCatalogueList();
    }, [])

    return (
        <>
            <Space direction={"vertical"} style={{width: '100%'}}>
                <CustomPageTitle>Request</CustomPageTitle>
                <CustomButton type="primary" onClick={() => {setUploadCatalogueModelVisible(true)}} >
                    New Catalogue
                </CustomButton>
                <CustomTable dataSource={dataSource} columns={columns} loading={loading} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} totalElements={totalElements}/>
            </Space>

            <Modal title="Upload New Catalogue" open={uploadCatalogueModelVisible}
                   onOk={() => setUploadCatalogueModelVisible(false)}
                   onCancel={() => setUploadCatalogueModelVisible(false)} footer={null}
            >
                <UploadCatalogue
                    onCloseModal={() => {
                        setLoading(true)
                        setUploadCatalogueModelVisible(false)
                        onGetCatalogueList()
                    }}
                />
            </Modal>
        </>
    )
}