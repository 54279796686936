import {Button, Table} from "antd";
import {paginationHelper} from "../../Helpers/Table";

export const CustomTable = ({  children, dataSource, columns, loading, totalElements, setCurrentPage, setPageSize, currentPage, pageSize, ...props }) => {
    return (
        <div style={{ padding: 30, backgroundColor: '#F6E7EA', borderRadius: 30 }}>
            <Table dataSource={dataSource} columns={columns} loading={loading} pagination={paginationHelper(totalElements, setCurrentPage, setPageSize, currentPage, pageSize)} />
        </div>
    )
}
