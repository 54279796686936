import "./ClientRequestPage.less";
import {Button, Col, Form, Input, Row, Space} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {showSuccessNotification} from "../../../Components/Notification/Notification";
import {CustomInput} from "../../../Components/CustomInput/CustomInput";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";

import personIcon from "../../../Assets/icons/person.png";
import envelopeIcon from "../../../Assets/icons/envelope.png";
import companyIcon from "../../../Assets/icons/building.png";
import houseIcon from "../../../Assets/icons/house.png";
import locationIcon from "../../../Assets/icons/location.png";
import earthIcon from "../../../Assets/icons/earth.png";
import phoneIcon from "../../../Assets/icons/phone.png";
import factoryIcon from "../../../Assets/icons/factory.png";
import {CustomPrefix} from "../../../Components/CustomPrefix/CustomPrefix";
import logo from "../../../Assets/transparent-bg-white-logo.png";
import {Header} from "antd/es/layout/layout";

export const WebClientRequestPage = () => {

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onFinish = (values) => {
		setLoading(true);
		backendAxiosInstance.post('/api/client/catalogue/info', {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			company: values.company,
			address: values.address,
			postcode: values.postcode,
			city: values.city,
			state: values.state,
			country: values.country,
			contactNumber: values.contactNumber,
			occupation: values.occupation,
			phone: values.phone,
			industry: values.industry
		})
			.then(() => {
				showSuccessNotification("Request sent successfully");
				form.resetFields();
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onFinishFailed = () => {
		console.log("onFinishFailed");
	}

	return (
		<>
			<Header style={{ backgroundColor: 'red', textAlign: 'center', height: "13vh" }}>
				<Space direction={"horizontal"} size={70} align={"center"} style={{ marginTop: '1%' }}>
					<img src={logo} style={{ width: '10vw', minWidth: '80px' }} />
					<h1 style={{ color: 'white', fontSize: 50, fontWeight: 300, marginTop: 12 }}>Customer Information</h1>
				</Space>
			</Header>
			<div className={"web-request-form-container"}>
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Row gutter={[80, 0]}>
						<Col xl={12} sm={24}>
							<Form.Item
								name="firstName"
								rules={[{ required: true, message: 'Please input your first name!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"First Name"} style={{ height: "5vh", fontSize: '18px'}} />
							</Form.Item>
						</Col>
						<Col xl={12} sm={24}>
							<Form.Item
								name="lastName"
								rules={[{ required: true, message: 'Please input your last name!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"Last Name"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[80, 0]}>
						<Col xl={12} sm={24}>
							<Form.Item
								name="occupation"
								rules={[{ required: true, message: 'Please input your occupation!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"Occupation"} style={{ height: "5vh", fontSize: '18px'}} />
							</Form.Item>
						</Col>
						<Col xl={12} sm={24}>
							<Form.Item
								name="contactNumber"
								rules={[{ required: true, message: 'Please input your contact number!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={phoneIcon} />} placeholder={"Contact Number"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
					</Row>

					<Form.Item
						name="email"
						rules={[{ required: true, message: 'Please input your email!', whitespace: true }, { type: 'email', message: 'The input is not valid E-mail!' }]}
					>
						<CustomInput prefix={<CustomPrefix src={envelopeIcon} />} placeholder={"Email"} style={{ height: "5vh", fontSize: '18px' }} />
					</Form.Item>

					<Row gutter={[80, 0]}>
						<Col xl={12} sm={24}>
							<Form.Item
								name="company"
								rules={[{ required: true, message: 'Please input your company!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={companyIcon} />} placeholder={"Company"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
						<Col xl={12} sm={24}>
							<Form.Item
								name="industry"
								rules={[{ required: true, message: 'Please input your industry!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={factoryIcon} />} placeholder={"Industry"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
					</Row>

					<Form.Item
						name="address"
						rules={[{ required: true, message: 'Please input your address!', whitespace: true }]}
					>
						<CustomInput prefix={<CustomPrefix src={houseIcon} />} placeholder={"Address"} style={{ height: "5vh", fontSize: '18px' }} />
					</Form.Item>

					<Row gutter={[80, 4]}>
						<Col xl={12} sm={24}>
							<Form.Item
								name="postcode"
								rules={[{ required: true, message: 'Please input your postcode!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={locationIcon} />} placeholder={"Postcode"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
						<Col xl={12} sm={24}>
							<Form.Item
								name="city"
								rules={[{ required: true, message: 'Please input your city!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={houseIcon} />} placeholder={"City"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[80, 4]}>
						<Col xl={12} sm={24}>
							<Form.Item
								name="state"
								rules={[{ required: true, message: 'Please input your state!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={locationIcon} />} placeholder={"State"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
						<Col xl={12} sm={24}>
							<Form.Item
								name="country"
								rules={[{ required: true, message: 'Please input your country!', whitespace: true }]}
							>
								<CustomInput prefix={<CustomPrefix src={earthIcon} />} placeholder={"Country"} style={{ height: "5vh", fontSize: '18px' }} />
							</Form.Item>
						</Col>
					</Row>

					<div style={{ textAlign: 'center' }}>
						<CustomButton type="primary" htmlType="submit" style={{ height: '50px', width: '100px', fontSize: '20px', borderRadius: '15px' }} loading={loading}>
							Submit
						</CustomButton>
					</div>
				</Form>
			</div>
		</>
	)
}
