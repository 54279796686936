import {Form, Image, Space} from "antd";
import {CustomInput} from "../../Components/CustomInput/CustomInput";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {CustomInputPassword} from "../../Components/CustomInputPassword/CustomInputPassword";
import {CustomButton} from "../../Components/CustomButton/CustomButton";
import {useState} from "react";
import {backendAxiosInstance} from "../../Axios/AxiosBase";
import {showSuccessNotification} from "../../Components/Notification/Notification";

import envelopeIcon from "../../Assets/icons/envelope.png";
import {CustomPrefix} from "../../Components/CustomPrefix/CustomPrefix";

export const InsertEmailPage = () => {

    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {

        setLoading(true)
        backendAxiosInstance.post('/api/auth/forgetPassword', {
            email: values.username
        })
            .then((res) => {
                showSuccessNotification("Email sent successfully, please check your email")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"login-container"}>
            <div style={{ textAlign: 'center' }}>
                <Image preview={false} src={require("../../Assets/transparent-bg-logo.png")} />
            </div>
            <br />
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your email!', whitespace: true }, { type: 'email', message: 'The input is not valid E-mail!' }]}
                >
                    <CustomInput prefix={<CustomPrefix src={envelopeIcon}/>} placeholder={"Email"} />
                </Form.Item>

                <div style={{ textAlign: 'center' }}>
                    <Space direction={"vertical"} align={"center"}>
                        <CustomButton type="primary" htmlType="submit" loading={loading}>
                            Process
                        </CustomButton>
                    </Space>
                </div>
            </Form>
        </div>
    )
}
