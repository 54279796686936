import {backendAxiosInstance} from "../Axios/AxiosBase";

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await backendAxiosInstance.post('/api/auth/refresh', {
        refreshToken: refreshToken
    })
    localStorage.setItem('accessToken', response.accessToken);
    localStorage.setItem('refreshToken', response.refreshToken);
}
