import {Col, Row, Space} from "antd";
import {Button, Checkbox, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {showSuccessNotification} from "../../../Components/Notification/Notification";
import {CustomInput} from "../../../Components/CustomInput/CustomInput";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";

import personIcon from "../../../Assets/icons/person.png";
import envelopeIcon from "../../../Assets/icons/envelope.png";
import companyIcon from "../../../Assets/icons/building.png";
import houseIcon from "../../../Assets/icons/house.png";
import locationIcon from "../../../Assets/icons/location.png";
import earthIcon from "../../../Assets/icons/earth.png";
import {CustomPrefix} from "../../../Components/CustomPrefix/CustomPrefix";

export const PersonalInfoPage = () => {

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const getUserInfo = () => {
        backendAxiosInstance.get("/user/changeInfo")
            .then((res) => {
                form.setFieldsValue(res);
            })
    }

    const onFinish = (values) => {
        setLoading(true);
        backendAxiosInstance.put('/api/user/changeInfo', values)
            .then(() => {
                showSuccessNotification("Update personal info successfully")
            })
            .finally(() => {
                setLoading(false)
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        getUserInfo();
    }, [])

    return (
        <Space direction={"vertical"} style={{width: '60%'}}>
            <CustomPageTitle>Personal / Info</CustomPageTitle>
            <Form
                form={form}
                labelCol={{span: 2}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row gutter={[ 80, 4 ]}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'Please input your first name!', whitespace: true }]}
                        >
                            <CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"First Name"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your last name!', whitespace: true }]}
                        >
                            <CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"Last Name"} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[ 80, 4 ]}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'Please input your email!'}, { email: true, message: 'Please input a valid email!'}]}
                        >
                            <CustomInput prefix={<CustomPrefix src={envelopeIcon} />} placeholder={"Email"} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="company"
                    rules={[{required: true, message: 'Please input your company!'}]}
                >
                    <CustomInput prefix={<CustomPrefix src={companyIcon} />} placeholder={"Company"} />
                </Form.Item>

                <Form.Item
                    name="address"
                    rules={[{required: true, message: 'Please input your address!'}]}
                >
                    <CustomInput prefix={<CustomPrefix src={houseIcon} />} placeholder={"Address"} />
                </Form.Item>

                <Row gutter={[ 80, 4 ]}>
                    <Col span={12}>
                        <Form.Item
                            name="postcode"
                            rules={[{required: true, message: 'Please input your postcode!'}]}
                        >
                            <CustomInput prefix={<CustomPrefix src={locationIcon} />} placeholder={"Postcode"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="city"
                            rules={[{required: true, message: 'Please input your city!'}]}
                        >
                            <CustomInput prefix={<CustomPrefix src={houseIcon} />} placeholder={"City"} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[ 80, 4 ]}>
                    <Col span={12}>
                        <Form.Item
                            name="state"
                            rules={[{required: true, message: 'Please input your state!'}]}
                        >
                            <CustomInput prefix={<CustomPrefix src={locationIcon} />} placeholder={"State"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="country"
                            rules={[{required: true, message: 'Please input your country!'}]}
                        >
                            <CustomInput prefix={<CustomPrefix src={earthIcon} />} placeholder={"Country"} />
                        </Form.Item>
                    </Col>
                </Row>

                <CustomButton type="primary" htmlType="submit" loading={loading}>
                    Submit
                </CustomButton>
            </Form>
        </Space>
    )
}
