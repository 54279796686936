import {Button, Modal, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {displayDateTime} from "../../../Helpers/DateTime";
import {CustomPageTitle} from "../../../Components/CustomPageTitle/CustomPageTitle";
import {CustomTable} from "../../../Components/CustomTable/CustomTable";

export const CatalogueHistoryPage = () => {

    const [requestId, setRequestId] = useState(null);
    const [approveRequestModalVisible, setApproveRequestModalVisible] = useState(false);
    const [rejectRequestModalVisible, setRejectRequestModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Catalogue',
            dataIndex: 'catalogueName',
            key: 'catalogueName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Registration Time',
            dataIndex: 'created',
            key: 'created',
            render: (text, record) => {
                return (
                    displayDateTime(record.created)
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }
    ];

    const onGetCatalogueHistoryList = () => {
        setLoading(true)
        backendAxiosInstance.get('/api/catalogue/history?sortOrder=DESC')
            .then((res) => {
                setDataSource(res.data);
                setTotalElements(res.totalElements);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        onGetCatalogueHistoryList();
    }, [])

    return (
        <>
            <Space direction={"vertical"} style={{width: '100%'}}>
                <CustomPageTitle>Request History</CustomPageTitle>
                <CustomTable dataSource={dataSource} columns={columns} loading={loading} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} totalElements={totalElements}/>
            </Space>
        </>
    )
}
