import {useEffect, useState} from "react";
import {Form, Image, Space} from "antd";
import {backendAxiosInstance} from "../../Axios/AxiosBase";
import {showSuccessNotification} from "../../Components/Notification/Notification";
import {CustomInput} from "../../Components/CustomInput/CustomInput";
import {UserOutlined} from "@ant-design/icons";
import {CustomButton} from "../../Components/CustomButton/CustomButton";
import {useQuery} from "../../Hooks/useQuery";
import {useNavigate} from "react-router-dom";
import {CustomInputPassword} from "../../Components/CustomInputPassword/CustomInputPassword";

import lockIcon from "../../Assets/icons/lock.png";
import {CustomPrefix} from "../../Components/CustomPrefix/CustomPrefix";

export const ResetPasswordPage = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const query = useQuery();

    const onFinish = (values) => {

        setLoading(true)
        backendAxiosInstance.post('/api/auth/resetPassword', {
            id: query.get("id"),
            ...values
        })
            .then((res) => {
                showSuccessNotification("Password changed successfully")
                navigate('/login')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!query.get("id")) {
            navigate('/login')
        }
    }, [])

    return (
        <div className={"login-container"}>
            <div style={{ textAlign: 'center' }}>
                <Image preview={false} src={require("../../Assets/transparent-bg-logo.png")} />
            </div>
            <br />
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"New password"} />
                </Form.Item>

                <Form.Item
                    name="confirmedPassword"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <CustomInputPassword prefix={<CustomPrefix src={lockIcon}/>} placeholder={"Confirmed password"} />
                </Form.Item>

                <div style={{ textAlign: 'center' }}>
                    <Space direction={"vertical"} align={"center"}>
                        <CustomButton type="primary" htmlType="submit" loading={loading}>
                            Reset
                        </CustomButton>
                    </Space>
                </div>
            </Form>
        </div>
    )
}
