import './App.less';
import {AdminLayout} from "./Layouts/Admin/AdminLayout";
import {LoginPage} from "./Pages/Login/LoginPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {DashboardPage} from "./Pages/Dashboard/DashboardPage";
import {useEffect} from "react";
import {AdminPage} from "./Pages/UserManagement/Admin/AdminPage";
import {RegistrationPage} from "./Pages/UserManagement/Registration/RegistrationPage";
import {CataloguePage} from "./Pages/Resource/Catalogue/CataloguePage";
import {UnauthorizedPage} from "./Pages/Unauthorized/UnauthorizedPage";
import {NotFoundPage} from "./Pages/NotFound/NotFoundPage";
import {SignUpPage} from "./Pages/SignUp/SignUpPage";
import {CatalogueHistoryPage} from "./Pages/Resource/Catalogue/CatalogueHistoryPage";
import {PersonalInfoPage} from "./Pages/Personal/Info/PersonalInfoPage";
import {ChangePasswordPage} from "./Pages/Personal/ChangePassword/ChangePasswordPage";
import {ClientLayout} from "./Layouts/Client/ClientLayout";
import {MainLayout} from "./Layouts/Main/MainLayout";
import {InsertEmailPage} from "./Pages/ForgetPassword/InsertEmailPage";
import {ResetPasswordPage} from "./Pages/ForgetPassword/ResetPasswordPage";
import {ClientRequestPage} from "./Pages/Client/ClientRequestPage";
import {TourPage} from "./Pages/Resource/Tour/TourPage";
import {CatalogueUpload} from "./Pages/Resource/Catalogue/CatalogueUpload";

function App() {

    useEffect(() => {
        require("./Theme/Default/DefaultTheme.less");
    }, []);

    return (
        <>
            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<MainLayout />}>
                        <Route path="/" element={<LoginPage/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/sign-up" element={<SignUpPage/>}/>
                        <Route path="/forget-password/insert-email" element={<InsertEmailPage />}/>
                        <Route path="/forget-password/reset" element={<ResetPasswordPage />}/>
                    </Route>

                    <Route path="/" element={<AdminLayout />}>
                        <Route path="/dashboard" element={<DashboardPage />}/>
                        <Route path="/user-management/admin" element={<AdminPage />}/>
                        <Route path="/user-management/registration" element={<RegistrationPage />}/>
                        <Route path="/resource/catalogue/upload" element={<CatalogueUpload/>}/>
                        <Route path="/resource/catalogue/request" element={<CataloguePage />}/>
                        <Route path="/resource/catalogue/history" element={<CatalogueHistoryPage />}/>
                        <Route path="/resource/tour" element={<TourPage/>}/>
                        <Route path="/personal/info" element={<PersonalInfoPage />}/>
                        <Route path="/personal/change-password" element={<ChangePasswordPage />}/>
                    </Route>

                    <Route path="/client" element={<ClientLayout />}>
                        <Route path="/client/request" element={<ClientRequestPage />}/>
                    </Route>

                    <Route path="/403" element={<UnauthorizedPage />}/>
                    <Route path="/404" element={<NotFoundPage />}/>
                    <Route path="*" element={<Navigate to="/404" replace/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
