import {Input} from "antd";

export const CustomInput = ({  children, style, prefix, ...props }) => {
    return (
        <div className="input-group prefix">
            {prefix &&
                <span className="input-group-addon">{prefix}</span>}
            <Input {...props} style={{height: "5vh", fontSize: '1.2vw', backgroundColor: '#ffe5e9', ...style }}>{ children }</Input>
        </div>
    )
}
