import React, {useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";

import "./MainLayout.less";

export const MainLayout = () => {

    return (
        <>
            <div className={"main-container"}>
                <Outlet />
            </div>
        </>
    )
}
