import {Button, DatePicker, Form, Upload} from "antd";
import {useState} from "react";
import {backendAxiosInstance} from "../../../Axios/AxiosBase";
import {showErrorNotification, showSuccessNotification} from "../../../Components/Notification/Notification";
import {CustomInput} from "../../../Components/CustomInput/CustomInput";
import {CustomButton} from "../../../Components/CustomButton/CustomButton";
import {UploadOutlined} from "@ant-design/icons";

export const UploadCatalogue = ({onCloseModal = () => {}}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        console.log(values);
        setLoading(true)
        backendAxiosInstance.post(`/api/resource/catalogue`, {
            name: values.name,
            url: values.file.file.xhr.responseURL.split("?")[0]
        })
            .then((res) => {
                showSuccessNotification("Catalogue created successfully")
                onCloseModal()
            })
            .catch((err) => {
                showErrorNotification(err)
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const getUploadFileUrl = (file) => {
        console.log(file);
        return backendAxiosInstance.post("/api/document/upload/url", {
            extension: file.name.split(".").pop(),
            type: "catalogue"
        })
    };


    return (
        <Form
            form={form}
            labelCol={{span: 2}}
            initialValues={{remember: true}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input the access name!', whitespace: true }]}
            >
                <CustomInput placeholder={"Name"} />
            </Form.Item>

            <Form.Item
                name="file"
                rules={[{required: true, message: 'must have a file uploaded'}]}
            >
                <Upload
                    action={getUploadFileUrl}
                    method="PUT"
                    headers={{"Content-Type": "application/octet-stream"}}

                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>

            </Form.Item>



            <CustomButton type="primary" htmlType="submit" loading={loading}>
                Submit
            </CustomButton>
        </Form>
    )
}