import {useState} from "react";
import {backendAxiosInstance} from "../../Axios/AxiosBase";
import {Button, Form, Image, Input, Space} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import "./LoginPage.less"
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {CustomButton} from "../../Components/CustomButton/CustomButton";
import {CustomInput} from "../../Components/CustomInput/CustomInput";
import {CustomInputPassword} from "../../Components/CustomInputPassword/CustomInputPassword";
import jwtDecode from "jwt-decode";
import {CustomPrefix} from "../../Components/CustomPrefix/CustomPrefix";

import personIcon from "../../Assets/icons/person.png";
import lockIcon from "../../Assets/icons/lock.png";

export const LoginPage = () => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const onFinish = (values) => {
        setLoading(true)
        backendAxiosInstance.post('/api/auth/login', {
            username: values.username,
            password: values.password
        })
            .then((res) => {
                const redirectUrl = searchParams.get('redirect-url')
                console.log(redirectUrl);
                if (!!redirectUrl) {
                    window.location.assign(`${redirectUrl}?editor-token=${res.accessToken}`);
                    return;
                }

                const decoded = jwtDecode(res.accessToken)

                localStorage.setItem('accessToken', res.accessToken)
                localStorage.setItem('refreshToken', res.refreshToken)
                localStorage.setItem('role', decoded.ROLE)
                localStorage.setItem('firstName', decoded.FIRST_NAME)
                localStorage.setItem('lastName', decoded.LAST_NAME)

                if (decoded.role === 'SUPER_ADMIN') {
                    navigate('/user-management/admin')
                } else {
                    navigate('/resource/catalogue/request')
                }
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={"login-container"}>
            <div style={{ textAlign: 'center' }}>
                <Image preview={false} src={require("../../Assets/transparent-bg-logo.png")} />
            </div>
            <br />
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your email!', whitespace: true }, { type: 'email', message: 'The input is not valid E-mail!' }]}
                >
                    <CustomInput prefix={<CustomPrefix src={personIcon} />} placeholder={"Enter Email"} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!', whitespace: true }]}
                >
                    <CustomInputPassword prefix={<CustomPrefix src={lockIcon} />} placeholder={"Password"} />
                </Form.Item>

                <div style={{ textAlign: 'center' }}>
                    <Space direction={"vertical"} align={"center"}>
                        <CustomButton type="link" htmlType="button" loading={loading} style={{ color: "gray" }} onClick={() => navigate("/forget-password/insert-email")}>
                            Forget Password?
                        </CustomButton>
                        <CustomButton type="primary" htmlType="submit" loading={loading} style={{ width: "23vw" }}>
                            Login
                        </CustomButton>
                        <CustomButton type={"default"} htmlType="button" loading={loading} style={{ width: "23vw" }} onClick={() => navigate("/sign-up")}>
                            Sign Up
                        </CustomButton>
                    </Space>
                </div>
            </Form>
        </div>
    )
}
