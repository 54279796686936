import {Button} from "antd";

import "./CustomTableFailedButton.less";
import {tableButtonRedBg, tableButtonRedText} from "../../Assets/Styles/variables";

export const CustomTableFailedButton = ({  children, style, ...props }) => {
    return (
        <Button {...props} className={"failed-button"} style={{ paddingTop: 0, height: '5vh', width: '5.5vw', fontSize: '1vw', borderRadius: '15px', backgroundColor: tableButtonRedBg, color: tableButtonRedText, ...style }}>{ children }</Button>
    )
}
